import service from './request';

export function initFB() {
  var chatbox = document.getElementById('fb-customer-chat');
  return service
    .get('/chat')
    .then((res) => {
      chatbox.setAttribute('page_id', res.pageId);
      chatbox.setAttribute('attribution', 'biz_inbox');
      chatbox.setAttribute('theme_color', res.themeColor);
      chatbox.setAttribute('logged_in_greeting', res.loginGreeting);
      chatbox.setAttribute('logged_out_greeting', res.logoutGreeting);
      chatbox.setAttribute(
        'greeting_dialog_display',
        res.minimized ? 'hide' : 'show'
      );
      chatbox.setAttribute('greeting_dialog_delay', 4);
    })
    .then(() => {
      window.fbAsyncInit = function() {
        window.FB.init({
          xfbml: true,
          version: 'v11.0',
        });
      };

      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
  // .catch((err) => console.error('fb chat plugin not loaded'));
}

export function initButton() {
  return service.get('/chat').then((res) => {
    (function() {
      var options = {
        facebook: res.pageId,
        call_to_action: res.loginGreeting,
        position: 'right',
      };
      var proto = document.location.protocol,
        host = 'getbutton.io',
        url = proto + '//static.' + host;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = url + '/widget-send-button/js/init.js';
      s.onload = function() {
        window.WhWidgetSendButton.init(host, proto, options);
      };
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    })();
  });
}
