import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-tw';
import 'dayjs/locale/zh-tw';
import 'element-plus/lib/theme-chalk/index.css';
import App from './App.vue';
import promiseRouter from './router';
import store from './store';
import './index.css';
import pageable from 'pageable/dist/pageable.min';
import 'pageable/dist/pageable.min.css';
import 'hamburgers/dist/hamburgers.min.css';
import '@/assets/scss/styles.scss';
import { initButton } from './utils/initFB';

const app = createApp(App);

initButton().then(() =>
  promiseRouter().then((router) => {
    app
      .provide('pageable', pageable)
      .use(store)
      .use(router)
      .use(ElementPlus, { locale })
      .mount('#app');
  })
);
