import axios from 'axios';

const service = axios.create({
  // process.env.NODE_ENV === 'development' 來判斷是否開發環境
  baseURL: 'https://www.lumansion.com.tw/api',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return;
    } else {
      Promise.reject();
    }
  },
  (error) => {
    return Promise.reject();
  }
);

export default service;
