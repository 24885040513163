<template>
	<div
		class="home relative"
		:class="[currentRoute == '/home' ? 'home-dashboard' : '', isMobile ? 'home-mobile' : '']"
	>
		<!-- <div class="template"></div> -->
		<transition name="fade-out">
			<div
				v-if="!playEnded"
				class="overlay w-full absolute top-0 left-0"
			></div>
		</transition>
		<template v-if="alert.isEnabled">
			<el-alert
				:title="alert.message"
				:type="alert.messageStatus"
				center
				show-icon
			>
			</el-alert>
		</template>
		<v-nav />

		<div class="content">
			<router-view v-slot="{ Component }">
				<transition
					name="move"
					mode="out-in"
				>
					<component
						:is="Component"
						:id="menuId"
					/>
				</transition>
			</router-view>
		</div>

		<v-footer v-if="currentRoute != '/catch'" />
	</div>

</template>

<script>
// @ is an alias to /src
import { computed, onMounted, onUnmounted, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { fetchApi } from "../utils/fetchApi";
import vNav from "../components/Nav.vue";
import vFooter from "../components/Footer.vue";
import { _detectMobile, _removeResizeListener } from "../utils/helpers";

export default {
	name: "Home",
	components: {
		vNav,
		vFooter,
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const collapse = computed(() => store.state.collapse);
		const menuId = computed(() => store.state.enabledMenuId);
		const menuItems = computed(() => store.state.menuItems);
		const playEnded = computed(() => store.state.playEnded);
		const isMobile = computed(() => store.state.isMobile);

		const alert = reactive({
			message: "",
			messageStatus: "",
			isEnabled: false,
		});

		const currentRoute = computed(() => route.path);

		onMounted(async () => {
			await fetchApi.alert().then((res) => {
				alert.messageStatus = res.messageStatus;
				alert.message = res.message;
				alert.isEnabled = res.isEnabled;
			});
			if (currentRoute.value != "/home") {
				store.commit("handlePlayEnded", true);
			}

			const setVh = () => {
				// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
				let vh = window.innerHeight * 0.01;
				// Then we set the value in the --vh custom property to the root of the document
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			};
			setVh();
			store.commit("handleIsMobile", _detectMobile());
			window.addEventListener("resize", function () {
				setVh();
				store.commit("handleIsMobile", _detectMobile());
			});
		});
		onUnmounted(() => _removeResizeListener());

		return {
			isMobile,
			collapse,
			menuId,
			menuItems,
			currentRoute,
			alert,
			playEnded,
		};
	},
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/styles.scss";
.home {
	width: 100%;
	height: 100%;
	position: relative;
	background: url("../assets/img/bg_paper.jpg") top repeat;
	background-size: 100%;
	@media (max-width: 1023px) {
		background: url("../assets/img/mobile-bg.jpg") top repeat;
	}
    >.footer {
        background: url("../assets/img/bg_paper.jpg") top repeat;
        @media (max-width: 1023px) {
            background: url("../assets/img/mobile-bg.jpg") top repeat;
        }
    }
}

.home-dashboard {
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
}

.el-alert {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0.85;
}

.overlay {
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
	background: $white;
	z-index: 15;
}
// .template {
// 	background: url("../assets/img/mobile-layout-05.jpg") top no-repeat;
// 	// height: calc(var(--vh, 1vh) * 100);
// 	height: 100%;
// 	z-index: 16;
// 	opacity: 0.3;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 428px;
// 	pointer-events: none;
// }
.fade-out-enter-active,
.fade-out-leave-active {
	transition: opacity 0.5s ease-in-out;
}

.fade-out-enter-from,
.fade-out-leave-to {
	opacity: 0;
}
</style>
