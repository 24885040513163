export const _detectMobile = () => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  if (viewportWidth <= 1024 && viewportWidth < viewportHeight) {
    return true;
  } else {
    return false;
  }
};

export const _removeResizeListener = () => {
  window.removeEventListener('resize', () => {});
};

export const separateParagraph = (text, separator) => {
  const paragraphs = text != '' ? text.split(separator) : '';
  if (Array.isArray(paragraphs)) {
    const separatedParagraphs = paragraphs
      .map((p) => (p != '' ? (p += separator) : ''))
      .filter((p) => p != '');
    return separatedParagraphs;
  } else {
    return [];
  }
};

export const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const joinParagraphs = (text) => {
  const regex = /<\/?p[^>]*>/g;
  const result = text != '' ? text.replace(regex, '') : '';
  return result;
};
