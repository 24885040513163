import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import service from '../utils/request';
import store from '../store/index';

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [],
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      title: '找不到頁面',
    },
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
];

const fetchedRoutes = async () => {
  const menuResponse = await service.get('/menu');
  const menu = menuResponse
    .map((i) => {
      return {
        path: i.path,
        name: i.name,
        meta: {
          title: i.title,
        },
        component: () =>
          import(/* webpackChunkName: "dashboard" */ `../views/${i.name}.vue`),
        position: i.position,
      };
    })
    .sort((a, b) => a.position - b.position);

  const fetchedRoutes = routes;
  fetchedRoutes[1].children = [...fetchedRoutes[1].children, ...menu];

  return fetchedRoutes;
};

export default async function promiseRouter() {
  const routes = await fetchedRoutes();
  const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      return { x: 0, y: 0 };
    },
    routes,
  });
  await store.dispatch('initApp').then(() => {
    router.beforeEach(async (to, from, next) => {
      document.title = `${to.meta.title} | 台南市定古蹟 盧經堂`;
      const menuItems = store.state.menuItems;
      store.commit(
        'handleEnabledMenuId',
        menuItems.find((i) => i.index == to.path).id
      );

      next();
    });
  });
  return router;
}
