import service from './request';

export const fetchApi = {
  menuBlocks: async (menuId) =>
    await service.get(`/menu/${menuId}`).then((res) => res.blockTypeAndIds),
  slider: async (id) => await service.get(`/slider/${id}`),
  priceList: async (id) => await service.get(`/priceList/${id}`),
  content: async (id) => await service.get(`/editorcontent/${id}`),
  embedUrl: async (id) => await service.get(`/embedUrl/${id}`),
  faq: async (id) => await service.get(`/faq/${id}`),
  registerDaysAvailable: async (year, month) =>
    await service.get(
      `/registertime/available-days?year=${year}&month=${month}`
    ),
  registerHoursAvailable: async (year, month, day) =>
    await service.get(
      `/registertime/available-hours?year=${year}&month=${month}&day=${day}`
    ),
  alert: async () => await service.get('/alert'),
  // TODO: registerTime and reservation
};

export const fetchData = {
  menuBlocks: async (menuId, idTitleObjArray) =>
    await fetchApi.menuBlocks(menuId).then((blocks) =>
      idTitleObjArray.forEach((pair) => {
        pair.id.value = blocks.find((b) => b.blockTitle == pair.title).blockId;
      })
    ),
  slider: async (id, images) =>
    await fetchApi
      .slider(id.value)
      .then((s) => {
        return {
          ...s,
          images: s.images.filter((i) => i != null),
        };
      })
      .then((data) => {
        if (data) Object.assign(images, data);
      }),
  content: async (id, content) =>
    await fetchApi.content(id.value).then((data) => {
      if (data) Object.assign(content, data);
    }),
  priceList: async (id, priceList) =>
    await fetchApi.priceList(id.value).then((data) => {
      if (data) Object.assign(priceList, data);
    }),
  embedUrl: async (id, embedUrl) =>
    await fetchApi.embedUrl(id.value).then((data) => {
      if (data) Object.assign(embedUrl, data);
    }),
  faq: async (id, faq) =>
    await fetchApi.faq(id.value).then((data) => {
      if (data) Object.assign(faq, data);
    }),
  alert: async (alert) =>
    await service.get('/alert').then((data) => {
      if (data) Object.assign(alert, data);
    }),
  chat: async (chat) =>
    await service.get('/chat').then((data) => {
      if (data) Object.assign(chat, data);
    }),
  contactUs: async (contactUs) =>
    await service.get('/contactus').then((data) => {
      if (data) Object.assign(contactUs, data);
    }),
};
