<template>
	<div class="footer leading-loose pt-12 xl:flex-row flex flex-col items-center justify-between lg:container lg:mx-auto lg:px-auto px-4 font-body tracking-widest text-left lg:h-auto pb-6 max-w-full">
		<div class="footer-title py-2 lg:py-0 lg:my-auto flex justify-center lg:flex-none text-left tracking-wide2x lg:tracking-wide">
			<div>
				<router-link to="/">
					{{ content.title }}
				</router-link>
			</div>
			<div
				v-if="isMobile"
				class="footer-social flex justify-center items-center"
			>
				<span class="mx-2"></span>
				<a
					:href="facebookLink"
					class="footer-facebook block w-6 h-6 mx-2"
				></a>
				<a
					:href="instagramLink"
					class="footer-instagram block w-6 h-6"
				></a>
			</div>
		</div>
		<div class="footer-address text-sm sm:text-base tracking-wide text-left flex justify-center"><a
				:href="mapUrl.embedUrl"
				:title="mapUrl.description"
				target="_blank"
			>{{ content.subTitle }} </a><span v-if="isMobile"> &nbsp;&nbsp;|&nbsp;&nbsp;{{ content.description }}</span></div>

		<div
			v-if="!isMobile"
			class="footer-phone-number text-sm sm:text-base tracking-wide text-left text-about"
		>{{ content.description }}</div>
		<div class="footer-resolution hidden lg:block text-sm sm:text-base tracking-wide text-left">建議環境：Chrome@1920x1080</div>
		<div class="footer-copyrights flex justify-center lg:flex-none my-auto text-about text-xs sm:text-base text-left tracking-wide sm:tracking-wide leading-loose">{{ content.subDescription }}</div>
		<div
			v-if="chat.loginGreeting != ''"
			class="btn btn-primary tooltip"
		>
			<div class="top custom-greeting" @click="clickTip">
				<p>{{chat.loginGreeting}}</p>
				<i></i>
			</div>
		</div>
	</div>

</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { fetchApi, fetchData } from "../utils/fetchApi";

export default {
	setup() {
		const store = useStore();
		const menuId = "7c0b42fa-5a98-4180-9b47-baf6fa53c1a9";
		const contentId = ref(null);
		const content = reactive({});
		const map = store.state.menuItems.filter((i) => i.index == "/map")[0];
		const urlId = ref(null);
		const mapUrl = reactive({});
		const chat = reactive({ loginGreeting: "" });
		const isMobile = computed(() => store.state.isMobile);
		const facebookLink = computed(() => store.state.facebookLink);
		const instagramLink = computed(() => store.state.instagramLink);
        const clickTip = () => {
            document.querySelector('.custom-greeting').classList.add('hidden');
        }

		onMounted(async () => {
			await fetchApi
				.menuBlocks(menuId)
				.then(
					(blocks) =>
						(contentId.value = blocks.find(
							(b) => b.blockType == "Contents"
						).blockId)
				);

			await fetchApi
				.menuBlocks(map.id)
				.then(
					(blocks) =>
						(urlId.value = blocks.find(
							(b) => b.blockType == "EmbedUrls"
						).blockId)
				);

			await fetchData.content(contentId, content);
			await fetchData.embedUrl(urlId, mapUrl);
			await fetchData.chat(chat);

			store.commit("handleMapUrl", mapUrl.embedUrl);
		});

		return {
			isMobile,
			content,
			mapUrl,
			chat,
			facebookLink,
			instagramLink,
            clickTip
        };
	}
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
.footer {
	width: 100%;
	position: relative;
	bottom: 0;
	left: 0;
	height: 15vh;
	height: calc(var(--vh, 1vh) * 15);
	/* padding-right: 3rem !important; */
	@media (min-width: 1280px) {
		padding-right: 4rem !important;
	}
	@media (min-width: 1536px) {
		padding-right: 8rem !important;
	}
	@media (min-width: 1700px) {
		padding-right: 15rem !important;
	}
	@media (max-width: 1024px) {
		height: 20vh;
		height: calc(var(--vh, 1vh) * 25);
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	@media (max-width: 768px) {
		height: auto;
		padding-top: 2rem;
		padding-bottom: 2rem;
		color: $about;
		> *:last-child {
			border-bottom: 1px solid $about;
		}
		> * {
			width: 100%;
			border-top: 1px solid $about;
		}
	}
	&-address {
		padding-right: 0.25rem;
	}
	&-phone-number {
		padding-left: 0.25rem;
	}
	&-resolution {
		padding-left: 0.25rem;
	}
	&-social {
		span {
			background: $about;
			height: 1rem;
			width: 1px;
		}
	}
	&-instagram {
		background: url("../assets/img/mobile-nav-instagram.svg") center
			no-repeat;
		background-size: 100%;
	}
	&-facebook {
		right: 18%;
		background: url("../assets/img/mobile-nav-facebook.svg") center
			no-repeat;
		background-size: 100%;
	}
	.tooltip {
		display: inline-block;
		position: absolute;
		text-align: left;
	}

	.tooltip .top {
		max-width: 200px;
		bottom: 85px;
		right: 23px;
		// top: -20px;
		// left: 50%;
		// transform: translate(-80%, -100%);
		padding: 10px 20px;
		color: #ffffff;
		background-color: #0091ff;
		font-weight: normal;
		font-size: 13px;
		border-radius: 8px;
		position: fixed;
		z-index: 20;
		box-sizing: border-box;
		box-shadow: 0 1px 8px transparent;
		visibility: visible;
		opacity: 1;
		transition: opacity 0.8s;
		line-height: 1.5;
	}

	.tooltip:hover .top {
		visibility: visible;
		opacity: 1;
	}

	.tooltip .top i {
		position: absolute;
		top: 100%;
		left: 88%;
		margin-left: -12px;
		width: 24px;
		height: 12px;
		overflow: hidden;
	}

	.tooltip .top i::after {
		content: "";
		position: absolute;
		width: 12px;
		height: 12px;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
		background-color: #0091ff;
		box-shadow: 0 1px 8px transparent;
	}
}
</style>