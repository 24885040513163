<template>
	<el-config-provider :locale="locale">
		<router-view />
	</el-config-provider>
</template>

<script>
import { ElConfigProvider } from "element-plus";
import zhTw from "element-plus/lib/locale/lang/zh-tw";

export default {
	components: {
		[ElConfigProvider.name]: ElConfigProvider,
	},
	setup() {
		return { locale: zhTw };
	},
};
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
.el-alert__title {
    font-size: 24px;
    line-height: 32px;
}
.el-alert {
    z-index: 15;
}
a[type="link"] {
	opacity: 0 !important;
}

// disable getbutton dialog
div[size] {
    display: none;
}
a[size] {
    display: block !important;
    opacity: 100 !important;
}
body > div > div > a[target] {
    display: none;
}
</style>
