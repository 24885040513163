<template>
	<div class="nav grid grid-cols-8 container mx-auto py-2 px-8 lg:py-4 xl:py-8 lg:mx-auto">
		<div class="relative nav-logo-wrapper flex items-center col-span-7 sm:col-span-5 md:col-span-3 lg:col-span-2 xl:col-span-2">
			<router-link
				to="/"
				class="w-40 sm:w-48 md:w-60 lg:w-full absolute"
			>
				<img
					src="../assets/img/logo.svg"
					class="nav-logo"
					alt=""
				>
			</router-link>
		</div>
		<div class="nav-leftspace col-span-1 sm:col-span-3 md:col-span-5 lg:col-span-6 xl:col-span-6 flex justify-end items-center">
			<button
				class="hamburger hamburger--squeeze"
				type="button"
				@click="toggleHamburger"
				:class="{'is-active': isHamburgerActive}"
			>
				<span class="hamburger-box">
					<span class="hamburger-inner"></span>
				</span>
			</button>
			<div
				class="menu-leftspace"
				v-if="isHamburgerActive"
				@click="toggleHamburger"
			></div>
			<div class="hidden xl:flex justify-between nav-link-wrapper">
				<div class="flex pr-8 nav-link-wrapper-inner">
					<div
						v-for="(item, index) in textMenu"
						:key="item.id"
						class="nav-link flex items-center text-secondary font-body  justify-center tracking-widest"
						:class="[item.index.replace('/', ''), { 'is-active': (item.index == currentRoute) || (item.index == '/creative' && currentRoute.includes('/creative'))}]"
						@click="isHamburgerActive ? toggleHamburger() : ''"
					>
						<span class="lg:hidden nav-circle mr-2 bg-about" :class="item.name"></span>
						<span
							class="hidden lg:block nav-separator bg-gray"
							:class="{ 'opacity-0': index == currentIndex + 1 }"
						></span>
						<router-link
							:to="item?.index"
							class="px-2 xl:px-4"
						>{{ item.title }}</router-link>
						<span
							v-if="index == textMenu.length - 1 || index == currentIndex"
							class="hidden lg:block nav-separator bg-gray"
						></span>
					</div>
				</div>

				<a
					class="flex items-center col-start-3 nav-icon nav-icon-facebook"
					:href="facebook?.embedUrl"
					target="_blank"
				></a>
				<a
					class="flex items-center nav-icon nav-icon-instagram ml-2"
					:href="instagram?.embedUrl"
					target="_blank"
				></a>

			</div>
		</div>
	</div>
</template>

<script>
import { ref, reactive, computed, onMounted, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { fetchData, fetchApi } from "../utils/fetchApi";

export default {
	setup() {
		const route = useRoute();
		const store = useStore();

		const instagramId = ref(null);
		const facebookId = ref(null);
		const mapId = ref(null);
		const isHamburgerActive = ref(false);

		const instagram = reactive({ embedUrl: "" });
		const facebook = reactive({ embedUrl: "" });
		const map = reactive({ embedUrl: "" });

		const currentRoute = computed(() => route.path);
		const currentIndex = ref(0);

		const selectMenuItem = (item) => {
			store.commit("handleEnabledMenuId", item.id);
		};

		const toggleHamburger = () => {
			isHamburgerActive.value = !isHamburgerActive.value;
			document
				.querySelector(".nav-link-wrapper")
				.classList.toggle("full-screen-menu");
			document.querySelector("html").classList.toggle("overflow-hidden");
			document.querySelector("body").classList.toggle("overflow-hidden");
			// document
			// 	.querySelectorAll(".footer div")
			// 	.forEach((e) => e.classList.toggle("opacity-0"));
		};

		const menuItems = computed(() => store.state.menuItems);
		const collapse = computed(() => store.state.collapse);
		const enabledMenuId = computed(() => store.state.enabledMenuId);
		const homeItem = menuItems.value[0];
		const textMenu = menuItems.value.slice(1, 7);
		const iconMenu = menuItems.value.slice(-9);

		const isCreativeProductsItemsActive = (path) => {
			const productsRoute = [
				"/creative/anti-drooling",
				"/creative/oriental",
				"/creative/costume",
				"/creative/poem",
				"/creative/calendars",
			];
			if (
				productsRoute.includes(currentRoute.value) &&
				currentRoute.value == path
			) {
				return true;
			}
			return false;
		};

		onMounted(async () => {
			const embedUrlBlockId = async (id) =>
				await fetchApi
					.menuBlocks(id)
					.then(
						(blocks) =>
							blocks.find((b) => (b.blockType = "EmbedUrls"))
								.blockId
					);
			instagramId.value = await embedUrlBlockId(iconMenu[1].id);
			facebookId.value = await embedUrlBlockId(iconMenu[0].id);
			mapId.value = await embedUrlBlockId(iconMenu[2].id);

			await fetchData.embedUrl(instagramId, instagram);
			await fetchData.embedUrl(facebookId, facebook);
			await fetchData.embedUrl(mapId, map);
			store.commit("handleFacebookLink", facebook.embedUrl);
			store.commit("handleInstagramLink", instagram.embedUrl);

			watchEffect(() => {
				switch (currentRoute.value) {
					case "/about":
						currentIndex.value = 0;
						break;
					case "/catch":
						currentIndex.value = 1;
						break;
					case "/anti-drooling":
						currentIndex.value = 2;
						break;
					case "/rental":
						currentIndex.value = 3;
						break;
					case "/creative":
						currentIndex.value = 4;
						break;
					case "/reservation":
						currentIndex.value = 5;
						break;
					default:
						break;
				}
			});
		});

		return {
			currentIndex,
			currentRoute,
			selectMenuItem,
			homeItem,
			textMenu,
			iconMenu,
			collapse,
			enabledMenuId,
			instagram,
			facebook,
			map,
			isHamburgerActive,
			toggleHamburger,
			isCreativeProductsItemsActive,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/styles.scss";
.nav {
	width: 100%;
	height: 20vh;
	height: calc(var(--vh, 1vh) * 20);
	// background: url("../assets/img/bg_paper.jpg") center no-repeat;
	@media (min-width: 1024px) {
        padding: 0 16px;
	}
	&-logo {
		max-width: 300px;
		max-height: 110px;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: left;
		&-wrapper {
			height: 100%;
		}
	}
	&-circle {
		width: 6px;
		height: 6px;
		border-radius: 50%;
	}
	&-separator {
		width: 1px;
		height: 1.1rem;
	}
	&-icon {
		width: 1.5rem;
		height: 1.5rem;
	}
	&-icon-facebook {
		background: url("../assets/img/facebook.svg") center no-repeat;
	}
	&-icon-instagram {
		background: url("../assets/img/instagram.svg") center no-repeat;
	}
	&-link {
		&.is-active {
			&:nth-child(1) {
				@apply text-about;
				span {
					@apply bg-about;
				}
			}
			&:nth-child(2) {
				@apply text-red;
				span {
					@apply bg-red;
				}
			}
			&:nth-child(3) {
				@apply text-orange;
				span {
					@apply bg-orange;
				}
			}
			&:nth-child(4) {
				@apply text-red2;
				span {
					@apply bg-red2;
				}
			}
			&:nth-child(5) {
				@apply text-yellow;
				span {
					@apply bg-yellow;
				}
			}
			&:nth-child(6) {
				@apply text-blue;
				span {
					@apply bg-blue;
				}
			}
		}
		&:last-child {
			padding-right: 0;
		}
		&-wrapper.full-screen-menu {
			display: flex;
			flex-direction: column;
			position: fixed;
			top: 0;
			right: 0;
			width: 15rem;
			height: 100vh; /* Fallback for browsers that do not support Custom Properties */
			height: calc(var(--vh, 1vh) * 100);
			z-index: 11;
			.nav-icon {
				position: absolute;
				bottom: 16.5%;
				z-index: 2;
				right: 10%;
				margin-left: 6px;
				margin-right: 6px;
				padding: 0;
				&-instagram {
					background: url("../assets/img/mobile-nav-instagram.svg")
						center no-repeat;
				}
				&-facebook {
					right: 18%;
					background: url("../assets/img/mobile-nav-facebook.svg")
						center no-repeat;
				}
			}
			.nav-link-wrapper-inner {
				position: relative;
				right: 0;
				width: 100%;
				height: 100%;
				text-align: left;
				justify-content: flex-end;
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				background: url("../assets/img/mobile-nav-bg.jpg") center
					no-repeat;
				background-size: cover;
				padding-left: 20%;
				.nav-link-group {
					height: 40%;
				}
				.nav-link {
					@media screen and (max-width: 1279px) and (orientation: landscape) {
						@apply text-sm;
					}
					@apply text-md text-about tracking-widest leading-loose;
				}
				.nav-circle {
					width: 3px;
					height: 3px;
					background: $about;
				}
				&:before {
					content: "";
					position: relative;
					background: url(../assets/img/mobile-nav-logo.svg) center
						no-repeat;
					background-size: 90% 90%;
					top: 0;
					left: 0;
					right: 0;
					margin-bottom: 1rem;
					width: 100%;
					height: 48%;
				}
				&:after {
					content: "";
					position: relative;
					background: url(../assets/img/mobile-nav-footer.svg) left
						no-repeat;
					background-size: contain;
					left: 1.25rem;
					right: 0;
					width: 100%;
					height: 20%;
					margin-top: 0.5rem;
					padding-left: 1rem;
				}
			}
		}
	}
	&-leftspace {
		height: 100%;
		// @media screen and (min-width: 1024px) {
		// 	padding-bottom: 0.75rem;
		// }
		// @media screen and (min-width: 1536px) {
		// 	padding-bottom: 0.5rem;
		// }
		// @media screen and (min-width: 1700px) {
		// 	padding-bottom: 1rem;
		// }
	}
}
.hamburger {
	transform: scale(0.7, 0.5);
	position: relative;
	z-index: 12;
	@apply xl:hidden;
	&.is-active {
		position: absolute;
		top: 0;
		right: 11rem;
		transform: scale(0.5);
	}
}
.menu-leftspace {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
	background: transparent;
	z-index: 9;
	overflow: hidden;
}
@media (max-width: 1024px) {
	.nav {
		height: 18vh;
		height: calc(var(--vh, 1vh) * 18);

		&-logo {
			max-width: 240px;
			&-wrapper {
				height: 100%;
			}
		}
		&-leftspace {
			height: 100%;
		}
	}
}
</style>