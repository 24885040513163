import { createStore } from 'vuex';
import service from '../utils/request';

export default createStore({
  state: {
    collapse: false,
    menuItems: [],
    enabledMenuId: '',
    playEnded: false,
    isMobile: false,
    facebookLink: '',
    instagramLink: '',
    mapUrl: '',
  },
  mutations: {
    handleCollapse(state, data) {
      state.collapse = data;
    },
    handleEnabledMenuId(state, data) {
      state.enabledMenuId = data;
    },
    handlePlayEnded(state, data) {
      state.playEnded = data;
    },
    handleIsMobile(state, data) {
      state.isMobile = data;
    },
    handleFacebookLink(state, data) {
      state.facebookLink = data;
    },
    handleInstagramLink(state, data) {
      state.instagramLink = data;
    },
    handleMapUrl(state, data) {
      StaticRange.mapUrl = data;
    },
  },
  actions: {
    initApp({ commit, state }) {
      return service
        .get('/menu')
        .then((res) => {
          const menuItems = res
            .map((i) => {
              return {
                id: i.id,
                icon: i.icon,
                index: i.path,
                title: i.title,
                position: i.position,
              };
            })
            .sort((a, b) => a.position - b.position);
          state.menuItems = menuItems;
        })
        .catch((err) => console.log(err));
    },
    // detectMobile({ commit, state }) {
    //   const detect = () => {
    //     const viewportWidth = window.innerWidth;
    //     const viewportHeight = window.innerHeight;
    //     if (viewportWidth <= 480 && viewportWidth < viewportHeight) {
    //       state.isMobile = true;
    //     } else {
    //       state.isMobile = false;
    //     }
    //   };
    //   detect();
    //   window.addEventListener('resize', () => {
    //     detect();
    //   });
    // },
  },
  modules: {},
});
